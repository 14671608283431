import { IReward, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { satisfiesRoles } from "../utilities/satisfies-roles";
import { rewardService } from "../services/reward.service";
import { RewardRow } from "../components/tables/custom-rows/reward-row";

export const RewardsView: FC<IViewProps> = (props) => (
  <CrudTable<IReward>
    service={rewardService}
    sortKey="title"
    options={{
      populate: ["organisation"],
      filter: { organisationId: props.routing.params.id },
    }}
    headings={{
      title: dictionary.title,
      requiredPoints: dictionary.required_points,
      organisation: dictionary.organisation,
      createdAt: dictionary.created_at,
    }}
    customRow={(reward) => (
      <RewardRow
        key={reward.id}
        reward={reward}
        attributes={{
          onClick: () =>
            Router.push(routes.editOrganisationReward.path, {
              id: props.routing.params.id,
              rewardId: reward.id,
            }),
        }}
      />
    )}
    customControls={
      satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
        <Button
          attributes={{
            onClick: () =>
              Router.push(routes.createOrganisationReward.path, {
                id: props.routing.params.id,
              }),
          }}
          appearance={"primary"}
        >
          {dictionary.new}
        </Button>
      )
    }
  />
);
