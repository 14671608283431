/* eslint-disable react-hooks/exhaustive-deps */
import { IJourney, IJourneyTeachingAid, Role } from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import Router from "react-history-router";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { Button } from "../components/controls/button";
import { CrudForm } from "../components/forms/crud-form";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { H1 } from "../components/typography/h1";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { dialog } from "../singletons/dialog";
import { satisfiesRoles } from "../utilities/satisfies-roles";
import { journeyService } from "../services/journey.service";
import { JourneyData } from "../components/elements/journey-data";
import { JourneyTeachingAidForm } from "../components/forms/journey-teaching-aid-form";
import { journeyTeachingAidService } from "../services/journey-teaching-aid.service";
import { JourneyTeachingAidRow } from "../components/tables/custom-rows/journey-teaching-aid-row";

export const JourneyDetailsView: FC<IViewProps> = (props) => {
  const [journey, setJourney] = useState<IJourney | null>(null);
  const isEditable = satisfiesRoles(Role.ADMIN);

  const fetchJourney = async () => {
    try {
      const { data } = await journeyService.get(props.routing.params.id, {
        populate: ["organisation", "teachingAids.teachingAid"],
      });
      setJourney(data);
    } catch {
      Router.push(routes.journeys.path);
    }
  };

  const updateTeachingAids = async (
    updatedTeachingAids: IJourneyTeachingAid[]
  ) => {
    try {
      await Promise.all(
        updatedTeachingAids.map((teachingAid) =>
          journeyTeachingAidService.patch(teachingAid)
        )
      );
      fetchJourney();
    } catch (error) {
      throw error;
    }
  };

  const updateOrderValues = async (
    newOrder: number,
    action: "edit" | "delete",
    originalOrder?: number
  ) => {
    // Whenever the order is not updated or the last teachingAid is deleted, fetch the journey
    // This is done to prevent the information from being out of sync
    if (
      (journey?.teachingAids?.length === newOrder && action === "delete") ||
      originalOrder === newOrder
    ) {
      fetchJourney();
      return;
    }
    // If the action is "delete"
    if (action === "delete") {
      // Filter out the teachingAids that have an order greater than newOrder
      const JourneyTeachingAids =
        journey?.teachingAids?.filter(
          (teachingAid) => teachingAid.order > newOrder
        ) ?? [];

      // Decrease the order of the remaining teachingAids by 1
      const updatedJourneyTeachingAids = JourneyTeachingAids.map(
        (teachingAid) => ({
          ...teachingAid,
          order: teachingAid.order - 1,
        })
      );

      updateTeachingAids(updatedJourneyTeachingAids);
    } else if (action === "edit") {
      // if there is no originalOrder, return
      if (!originalOrder) {
        return;
      }
      let updatedJourneyTeachingAids;
      if (newOrder > originalOrder) {
        // If the newOrder is greater than the originalOrder, decrease the order of the teachingAids between them by 1
        updatedJourneyTeachingAids = journey?.teachingAids
          ?.filter(
            (teachingAid) =>
              teachingAid.order > originalOrder && teachingAid.order <= newOrder
          )
          .map((teachingAid) => ({
            ...teachingAid,
            order: teachingAid.order - 1,
          }));
      } else {
        // If the newOrder is less than the originalOrder, increase the order of the teavchingAids between them by 1
        updatedJourneyTeachingAids = journey?.teachingAids
          ?.filter(
            (teachingAid) =>
              teachingAid.order >= newOrder && teachingAid.order < originalOrder
          )
          .map((teachingAid) => ({
            ...teachingAid,
            order: teachingAid.order + 1,
          }));
      }

      updateTeachingAids(updatedJourneyTeachingAids ?? []);
    }
  };

  useEffect(() => {
    fetchJourney();
  }, [props.routing.params.id]);

  if (!journey) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Card attributes={{ className: styles.card }}>
          <JourneyData journey={journey} />
          {isEditable && (
            <Button
              attributes={{
                onClick: () =>
                  Router.push(routes.editJourney.path, {
                    id: journey.id,
                  }),
              }}
            >
              {dictionary.edit_journey}
            </Button>
          )}
        </Card>
      </div>

      <div className={styles.right}>
        <H1>
          <span style={{ whiteSpace: "nowrap" }}>
            {dictionary.journey_teaching_aid}
          </span>
          {isEditable && (
            <Button
              size={"small"}
              attributes={{
                onClick: () => {
                  dialog.mount({
                    width: 650,
                    title: dictionary.add_journey_teaching_aid,
                    children: (
                      <CrudForm
                        messageSubject={dictionary.journey_teaching_aid}
                        service={journeyTeachingAidService}
                        form={JourneyTeachingAidForm}
                        model={{ journeyId: journey.id }}
                        fetchOptions={{ populate: ["teachingAid.asset"] }}
                        usePut
                        onDone={async () => {
                          dialog.unmount();
                          fetchJourney();
                        }}
                      />
                    ),
                  });
                },
              }}
            >
              {dictionary.add_journey_teaching_aid}
            </Button>
          )}
        </H1>
        <CrudTable<IJourneyTeachingAid>
          service={journeyTeachingAidService}
          sortKey="order"
          headings={{
            order: dictionary.order,
            teachingAid: dictionary.teaching_aid,
            awardedPoints: dictionary.awarded_points,
            type: dictionary.answerMethod,
          }}
          options={{
            filter: { journeyId: journey.id },
            populate: ["teachingAid"],
            limit: 20,
          }}
          hidePagination
          customRow={(journeyTeachingAid) => (
            <JourneyTeachingAidRow
              key={journeyTeachingAid.id}
              journeyTeachingAid={journeyTeachingAid}
              attributes={{
                onClick: () => {
                  dialog.mount({
                    width: 650,
                    title: dictionary.edit_journey_teaching_aid,
                    children: (
                      <CrudForm
                        fetchOptions={{ populate: ["teachingAid.asset"] }}
                        messageSubject={dictionary.journey_teaching_aid}
                        service={journeyTeachingAidService}
                        form={JourneyTeachingAidForm}
                        modelId={journeyTeachingAid.id}
                        canDelete={satisfiesRoles(Role.ADMIN)}
                        onDone={async (
                          updatedJourneyTeachingAid,
                          operation
                        ) => {
                          if (!operation) {
                            dialog.unmount();
                            return;
                          }

                          updateOrderValues(
                            updatedJourneyTeachingAid?.order ?? 0,
                            operation,
                            operation === "edit"
                              ? journeyTeachingAid.order
                              : undefined
                          );

                          dialog.unmount();
                        }}
                      />
                    ),
                  });
                },
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
  }),
  left: style({
    maxWidth: 300,
    flex: 1,
    margin: 15,
    marginTop: 0,
  }),
  right: style({
    flex: 1,
    margin: 15,
    marginTop: 0,
  }),
  card: style({
    marginBottom: 20,
  }),
  secondary: style({
    opacity: 0.54,
  }),
};
