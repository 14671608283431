/* eslint-disable react-hooks/exhaustive-deps */
import {
  AssetType,
  IJourney,
  IJourneyTeachingAid,
  ITeachingAid,
  TeachingAidType,
} from "@hulanbv/ssllow-packages";
import React, { FC, useEffect, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { Button } from "../controls/button";
import { Search } from "../controls/search";
import { Form } from "./form";
import { FormRow } from "./form-row";
import { teachingAidService } from "../../services/teaching-aid.service";
import { TeachingAidOption } from "../controls/option-templates/teaching-aid-option";
import { Checkbox } from "../controls/checkbox";
import { Select } from "../controls/select";
import { journeyTeachingAidTypeLabels } from "../../constants/journey-teaching-aid-type-labels";
import { Input } from "../controls/input";
import { journeyService } from "../../services/journey.service";
import { style } from "typestyle";
import { RichText } from "../controls/rich-text";
import { EmbeddedVideoPlayerElement } from "../elements/embedded-video-player.component";

export const JourneyTeachingAidForm: FC<IFormProps<IJourneyTeachingAid>> = (
  props
) => {
  const [journey, setJourney] = useState<IJourney | null>(null);
  const [selectedTeachingAid, setSelectedTeachingAid] =
    useState<ITeachingAid | null>(props.model?.teachingAid ?? null);

  const handleOnTeachingAidChange = async (teachingAidId: string) => {
    if (!teachingAidId) {
      setSelectedTeachingAid(null);
      return;
    }
    const { data } = await teachingAidService.get(teachingAidId);
    setSelectedTeachingAid(data);
  };

  const fetchJourney = async () => {
    try {
      const { data } = await journeyService.get(props.model?.journeyId ?? "", {
        populate: ["teachingAids"],
        pick: ["teachingAids"],
      });
      setJourney(data);
    } catch {}
  };

  useEffect(() => {
    fetchJourney();
  }, [props.model?.journeyId]);

  if (!journey) {
    return <></>;
  }

  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />
      <input type="hidden" name="journeyId" value={props.model?.journeyId} />

      <FormRow>
        <Select
          options={journeyTeachingAidTypeLabels}
          label={dictionary.answerMethod}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.type,
            name: "type",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Search<ITeachingAid>
          keyField="id"
          label={dictionary.teaching_aids}
          defaultValue={
            props.model?.teachingAid ? [props.model.teachingAid] : []
          }
          find={async (search) =>
            (
              await teachingAidService.getAll({
                search,
                searchScope: ["title"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={TeachingAidOption}
          onChange={(selection) => handleOnTeachingAidChange(selection[0])}
          disabled={props.disabled}
          name="teachingAidId"
          openOnFocus
          required
        />
      </FormRow>
      {selectedTeachingAid?.asset?.type === AssetType.IMAGE &&
        selectedTeachingAid?.asset?.path && (
          <FormRow>
            <div>
              <img
                className={styles.image}
                src={selectedTeachingAid.asset.path}
                alt=""
              />
            </div>
          </FormRow>
        )}
      {selectedTeachingAid?.type === TeachingAidType.VIDEO &&
        selectedTeachingAid?.url && (
          <FormRow>
            <EmbeddedVideoPlayerElement src={selectedTeachingAid.url} />
          </FormRow>
        )}
      {selectedTeachingAid?.type === TeachingAidType.TEXT &&
        selectedTeachingAid.description && (
          <FormRow>
            <RichText
              hideToolbar
              disabled={true}
              defaultValue={selectedTeachingAid.description}
            />
          </FormRow>
        )}

      <FormRow>
        <Checkbox
          label={dictionary.is_team_assignment}
          attributes={{
            disabled: props.disabled,
            checked: props.model?.isTeamAssignment,
            name: "isTeamAssignment",
          }}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.awarded_points}
          attributes={{
            type: "number",
            disabled: props.disabled,
            defaultValue: props.model?.awardedPoints,
            name: "awardedPoints",
            required: true,
          }}
        />
        <Input
          label={dictionary.order}
          attributes={{
            type: "number",
            disabled: props.disabled,
            readOnly: !props.model?.order,
            max: journey?.teachingAids?.length ?? 0,
            min: 1,
            defaultValue: props.model?.order
              ? props.model.order
              : (journey?.teachingAids?.length ?? 0) + 1,
            name: "order",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};

const styles = {
  image: style({
    width: "100%",
    height: "auto",
    maxHeight: 600,
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    margin: "0 auto",
  }),
};
