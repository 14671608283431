import { Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import bookmarkIcon from "../../assets/svg/icons/bookmark.svg";
import fileIcon from "../../assets/svg/icons/file-text.svg";
import homeIcon from "../../assets/svg/icons/home.svg";
import labelIcon from "../../assets/svg/icons/label.svg";
import layoutIcon from "../../assets/svg/icons/layout.svg";
import mailIcon from "../../assets/svg/icons/mail.svg";
import pieChartIcon from "../../assets/svg/icons/pie-chart.svg";
import rssIcon from "../../assets/svg/icons/rss.svg";
import thumbsUpIcon from "../../assets/svg/icons/thumbs-up.svg";
import journeyIcon from "../../assets/svg/icons/journey.svg";
import usersIcon from "../../assets/svg/icons/users.svg";
import { dictionary } from "../../constants/i18n/dictionary";
import { satisfiesRoles } from "../../utilities/satisfies-roles";
import { routes } from "../routing/routes";
import { NavigationItem } from "./navigation-item";

export const Navigation: FC = (props) => (
  <nav>
    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.news}>
        <img src={rssIcon} alt="" /> {dictionary.news}
      </NavigationItem>
    )}
    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.users}>
        <img src={usersIcon} alt="" /> {dictionary.users}
      </NavigationItem>
    )}
    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.organisations}>
        <img src={homeIcon} alt="" /> {dictionary.organisations}
      </NavigationItem>
    )}
    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.journeys}>
        <img src={journeyIcon} alt="" /> {dictionary.journeys}
      </NavigationItem>
    )}

    {satisfiesRoles(Role.ADMIN) && (
      <NavigationItem route={routes.categories}>
        <img src={bookmarkIcon} alt="" /> {dictionary.categories}
      </NavigationItem>
    )}
    {satisfiesRoles(Role.ADMIN) && (
      <NavigationItem route={routes.genres}>
        <img src={labelIcon} alt="" /> {dictionary.genres}
      </NavigationItem>
    )}

    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.surveys}>
        <img src={fileIcon} alt="" /> {dictionary.reflectors}
      </NavigationItem>
    )}
    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.motivations}>
        <img src={thumbsUpIcon} alt="" /> {dictionary.motivation}
      </NavigationItem>
    )}
    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.teachingAids}>
        <img src={thumbsUpIcon} alt="" /> {dictionary.teaching_aids}
      </NavigationItem>
    )}

    <NavigationItem route={routes.reports}>
      <img src={pieChartIcon} alt="" /> {dictionary.reports}
    </NavigationItem>

    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.reportTemplates}>
        <img src={layoutIcon} alt="" /> {dictionary.templates}
      </NavigationItem>
    )}
    {satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
      <NavigationItem route={routes.createInvite}>
        <img src={mailIcon} alt="" /> {dictionary.invite}
      </NavigationItem>
    )}
  </nav>
);
