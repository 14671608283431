import { IJourneyTeachingAidCompletion } from "@hulanbv/ssllow-packages";
import { CrudService, IHttpOptions } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<IJourneyTeachingAidCompletion> {
  constructor() {
    super(
      [environment.REACT_APP_API_URL, "journey-teaching-aid-completion"].join(
        "/"
      ),
      httpService
    );
  }

  async getTeachingAidCompletionsByTeamId(
    teamId: string,
    options?: IHttpOptions
  ) {
    return httpService.get(
      [this.controller, "team", teamId].join("/"),
      options
    );
  }

  async getTeachingAidCompletionsByOrganisationId(
    organisationId: string,
    options?: IHttpOptions
  ) {
    return httpService.get(
      [this.controller, "organisation", organisationId].join("/"),
      options
    );
  }
}

export const journeyTeachingAidCompletionService = new Service();
