import { IJourney } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { formatDate } from "../../utilities/date.utils";
import { Row } from "./row";

interface IProps {
  journey: IJourney;
}

export const JourneyData: FC<IProps> = (props) => (
  <div>
    <Row label={dictionary.title}>{props.journey.title}</Row>

    <Row label={dictionary.description}>{props.journey.description}</Row>
    <Row label={dictionary.organisation}>
      {props.journey.organisation?.name || "-"}
    </Row>
    <Row label={dictionary.created_at}>
      {formatDate(props.journey.createdAt!.toString(), dictionary.date_format)}
    </Row>
  </div>
);
