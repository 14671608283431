import { formatDate } from "../../utilities/date.utils";
import { en } from "./en";

export const nl: typeof en = {
  action_and_advice_buttons: "Actie- en advies knoppen",
  active: "Actief",
  add_button: "Knop toevoegen",
  add_journey_teaching_aid: "Ontwikkelpad leerhulp toevoegen",
  add_new_user: "Nieuwe gebruiker toevoegen",
  add_question: "Stelling toevoegen",
  add_section: "Sectie toevoegen",
  address: "Adres",
  administrator: "Administrator",
  advice: "Advies",
  advice_question: "Advies stelling",
  advice_question_placeholder: "Ik vind dat ik mijn werk {answer} goed doe",
  always: "Altijd",
  and: "en",
  answerMethod: "Antwoordmethode",
  answer: "Antwoord",
  answers: "Antwoorden",
  app_intro_without_salutation: "App intro (zonder aanhef)",
  article: "Artikel",
  ascending: "Oplopend",
  attitude: "Houding",
  available_to_other_experts: "Beschikbaar voor andere experts",
  avg: "Gem.",
  awarded_points: "Toegekende punten",
  back: "Terug",
  background_information: "Achtergrondinformatie",
  base_statements_on_reflector: "Baseer stellingen o.b.v. reflector",
  behaviour: "Gedrag",
  biography: "Biografie",
  birthdate: "Geboortedatum",
  brand_logo: "Merk logo",
  categories: "Categorieën",
  category: "Categorie",
  category_chart_report: "Categorie diagram rapportage",
  category_summary_report: "Categorie samenvattingsrapportage",
  center_description: "Omschrijving centreren",
  cherish_and_starting_points: "Koester- en uitgangspunten",
  cherish_point: "Koesterpunt",
  cherish_points: "Koesterpunten",
  choose_invites: "Kies uitnodigingen",
  choose_report_template: "Kies hier een sjabloon voor de rapportage",
  choose_base_template: "Kies basis sjabloon",
  city: "Stad",
  classification: "Classificatie",
  code: "Code",
  compare_with_preceeding: "Vergelijk met eerdere reflector",
  comparison_report: "Vergelijkingsrapportage",
  confirm_deletion: "Weet je zeker dat je dit wilt verwijderen?",
  confirm_importing_x_users: (userCount: number) =>
    `Weet je zeker dat je ${userCount} gebruikers wilt importeren?`,
  confirm_resend_mail:
    "Weet je zeker dat je de uitnodigingsmail opnieuw wilt versturen?",
  confirm_send_reminder:
    "Weet je zeker dat je een herinneringsmail wilt sturen?",
  confirm_reset_password:
    "Weet je zeker dat je het wachtwoord van deze gebruiker wilt resetten?",
  constructive: "Constructief",
  contact: "Contactpersoon",
  content: "Inhoud",
  context_codes: "Context codes",
  correct_answer: "Juist antwoord",
  create_category: "Categorie aanmaken",
  create_genre: "Genre aanmaken",
  create_invite: "Uitnodiging aanmaken",
  create_invite_link: "Creëer uitnodigingslink",
  invite_links: "Uitnodigingslinks",
  create_journey: "Ontwikkelpad aanmaken",
  create_motivation: "Motivatie aanmaken",
  create_news: "Nieuws aanmaken",
  create_organisation: "Organisatie aanmaken",
  create_report: "Rapportage aanmaken",
  create_reflector: "Reflector aanmaken",
  create_reward: "Beloning aanmaken",
  create_teaching_aid: "Leerhulp aanmaken",
  create_team: "Team aanmaken",
  create_user: "Gebruiker aanmaken",
  created_at: "Aangemaakt op",
  currently_loading: "Aan het laden",
  date_format: "DD-MM-YYYY",
  date_format_string: "dd-mm-jjjj",
  deadline: "Deadline",
  default: "Standaard",
  delete: "Verwijder",
  delete_template: "Verwijder sjabloon",
  derivative: "Afgeleid",
  descending: "Aflopend",
  description: "Omschrijving",
  destructive: "Destructief",
  development_advice: "Ontwikkeladvies",
  development_and_obstacle_points: "Ontwikkel- en belemmerpunten",
  development_capacity: "Ontwikkelvermogen",
  development_point: "Ontwikkelpunt",
  development_points: "Ontwikkelpunten",
  development_potential: "Ontwikkelvermogen",
  development_space: "Ontwikkelruimte",
  direct_invite: "Directe uitnodiging",
  direction: "Richting",
  discussion_report: "Dialoog rapportage",
  discussion_reports_arent_compatible_with_team_reports:
    "Dialoog rapportages worden niet ondersteund in team rapportages",
  dot_report: "Plotrapportage",
  download: "Download",
  download_selection: "Download selectie",
  dutch: "Nederlands",
  learning_duration_minutes: "Leerduur in minuten",
  edit: "Aanpassen",
  edit_answer: "Antwoord aanpassen",
  edit_category: "Categorie aanpassen",
  edit_genre: "Genre aanpassen",
  edit_invite: "Uitnodiging aanpassen",
  edit_journey: "Ontwikkelpad aanpassen",
  edit_journey_teaching_aid: "Ontwikkelpad leerhulp aanpassen",
  edit_motivation: "Motivatie aanpassen",
  edit_news: "Nieuws aanpassen",
  edit_question: "Stelling aanpassen",
  edit_organisation: "Organisatie aanpassen",
  edit_report: "Rapportage aanpassen",
  edit_section: "Sectie aanpassen",
  edit_reflector: "Reflector aanpassen",
  edit_reward: "Beloning aanpassen",
  edit_teaching_aid: "Leerhulp aanpassen",
  edit_team: "Team aanpassen",
  edit_template: "Sjabloon aanpassen",
  edit_user: "Gebruiker aanpassen",
  email: "E-mail",
  email_without_salutation_and_closing: "E-mail (zonder aanhef en afsluiting)",
  enable_reports: "Rapportages opvragen via de app",
  end_date: "Einddatum",
  english: "Engels",
  expert: "Expert",
  experts: "Experts",
  experts_or_spectators: "Experts/Toeschouwers",
  explanation: "Uitleg",
  expression: "Uiting",
  feeling: "Gevoel",
  female: "Vrouw",
  file: "Bestand",
  filter_on_category: "Filter op categorie",
  filter_on_genre: "Filter op genre",
  filter_on_classification: "Filter op classificatie",
  filter_on_label: "Filter op label",
  filter_on_result_type: "Filter op deelnemer resultaat type",
  filter_on_partner_result_type: "Filter op feedbackgever/team resultaat type",
  filters: "Filters",
  firstName: "Voornaam",
  force_new_page: "Forceer nieuwe pagina",
  gender: "Geslacht",
  generate: "Genereer",
  generate_report: "Genereer rapportage",
  generic_report: "Generiek rapportage",
  genre: "Genre",
  genres: "Genres",
  go_a_step_back: "Ga een stap terug",
  he: "Hij",
  her: "Haar",
  highlight_report: "Uitlichtingsrapportage",
  him: "Hem",
  his: "Zijn",
  image: "Afbeelding",
  import_users: "Importeer gebruikers",
  in_name_of_expert: "Uit naam van expert",
  incidentally: "Incidenteel",
  include_in_report: "Neem mee in rapportage",
  incorrect_credentials: "Onjuiste gebruikersnaam/wachtwoord combinatie",
  indicator: "Indicator",
  indicator_report: "Indicator rapportage",
  individual_persons: "Individuele personen",
  information_buttons: "Informatie knoppen",
  internal_use: "Intern gebruik",
  invitation_email_without_salutation_and_closing:
    "Uitnodigings e-mail (zonder aanhef en afsluiting)",
  invite: "Uitnodiging",
  invites_sent: "De uitnodigingen zijn verzonden",
  is_hidden_on_print: "Is verborgen bij het printen",
  is_team_assignment: "Is team opdracht",
  is_public: "Is publiekelijk",
  journey: "Ontwikkelpad",
  journey_details: "Ontwikkelpad details",
  journey_teaching_aid: "Ontwikkelpad leerhulp",
  journeys: "Ontwikkelpaden",
  label: "Label",
  language: "Taal",
  lastName: "Achternaam",
  legend: "Legenda",
  legend_extended: "Legenda (uitgebreid)",
  LID: "LID",
  login: "Inloggen",
  logout: "Uitloggen",
  mail_partners: "Mail gesprekspartners",
  mail_successfully_sent: "De mail is verzonden",
  male: "Man",
  manual: "Handmatig",
  maximum_number_of_questions: "Maximaal aantal stellingen",
  microlearning: "Microlearning",
  microlearnings: "Microlearnings",
  microlearning_footer: (fullName: string, date: Date) =>
    `Slow microlearning voor ${fullName} - Gegenereerd op ${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`,
  minimal_deviation_between_invitee_and_partner_answer:
    "Minimale verschil tussen deelnemer- en partner antwoord (%)",
  mostly: "Meestal",
  motivation: "Motivatie",
  name: "Naam",
  never: "Nooit",
  new: "Nieuw",
  new_page_after: "Nieuwe pagina na",
  new_page_before: "Nieuwe pagina voor",
  new_template: "Nieuw sjabloon",
  news: "Nieuws",
  news_item: "Nieuwsbericht",
  no: "Nee",
  no_results_found: "Geen resultaten gevonden",
  no_valid_embedded_video_url: "Geen geldige ingesloten video url",
  number_of_participants: "Aantal feedbackgevers",
  obstacle_point: "Belemmerpunt",
  obstacle_points: "Belemmerpunten",
  obstructive: "Belemmerend",
  omit_questions_occurring_in_previous_section:
    "Laat stellingen die in de vorige sectie voorkomen weg",
  open: "Open",
  options: "Opties",
  order: "Volgorde",
  organisation: "Organisatie",
  organisation_details: "Organisatie details",
  oganisation_view: "Beeld",
  organisations: "Organisaties",
  other: "Overig",
  overview_report: "Overzicht rapportage",
  overwrite_template: "Overschrijf bestaand sjabloon",
  participant: "Deelnemer",
  participants: "Deelnemers",
  partner_advice_question: "Partner advies stelling",
  partner_advice_question_placeholder:
    "{name} denkt {answer} dat {refer} {possessive} werk goed doet",
  partner_code: "Feedbackgeverscode",
  partner_motivation: "Partner motivatie",
  partner_question: "Feedbackgever stelling",
  partner_question_placeholder:
    "{name} vindt dat {refer} {possessive} werk goed doet",
  partner_score: "Feedbackgever score",
  partner: "Feedbackgever",
  partner__team: "Feedbackgever/team",
  partner__team_score: "Feedbackgever/team score",
  partners: "Feedbackgevers",
  partners_remaining: "Partner(s) resterend",
  password: "Wachtwoord",
  password_has_been_reset: "Het wachtwoord is gereset",
  personal_top_report: "Top rapportage (deelnemer score)",
  phone_number: "Telefoonnummer",
  postal_code: "Postcode",
  primary_color: "Primaire kleur",
  prioritize_question: "Prioriteer stelling",
  private_template: "Privé sjabloon",
  prizeCabinet: "Prijzenkast",
  profile_picture: "Profiel foto",
  publicly_available_for_users: "Publiekelijk beschikbaar voor deelnemers",
  question: "Stelling",
  question_placeholder: "Ik vind dat ik mijn werk goed doe",
  question_summary_report: "Vraag samenvattingsrapportage",
  question_type: "Stelling type",
  question_type_constructive_destructive: "Stelling type (constr., destr.)",
  questions: "Stellingen",
  quiz: "Quiz",
  report: "Rapportage",
  report_requests: "Rapportage aanvragen",
  reports: "Rapportages",
  report_footer: (subjectName: string, organisationName: string, date: Date) =>
    `Slow rapportage voor ${subjectName} (${organisationName}) - Gegenereerd op ${formatDate(
      date,
      "DD-MM-YYYY"
    )}`,
  required_points: "Benodigde punten",
  requires_partner_reviews: "Vereist feedbackgever beoordelingen",
  resend_invitation: "Stuur uitnodiging opnieuw",
  reset_password: "Reset wachtwoord",
  reward: "Beloning",
  reward_image: "Beloning afbeelding",
  rewarded_points: "Beloonde punten",
  rewards: "Beloningen",
  role: "Rol",
  save_as_new_template: "Opslaan als nieuw sjabloon",
  save_template: "Sjabloon opslaan",
  search: "Zoek",
  search_label: "Doorzoek label",
  select_a_reflector: "Selecteer een reflector",
  select_an_organisation: "Selecteer een organisatie",
  send: "Verzend",
  send_reminder: "Reminder sturen",
  send_report: "Verzend rapportage",
  share_the_following_url_with_potential_users:
    "Deel de volgende url met potentiële gebruikers",
  she: "Zij",
  show_access_code_to_user: "Toon toegangscode gesprekspartner aan deelnemer",
  show_indicator_during_reflector: "Toon indicator tijdens reflector",
  show_participants_during_reflector: "Toon gesprekspartners tijdens reflector",
  show_participant_results_to_user:
    "Toon gesprekspartner uitkomsten aan gebruiker",
  show_action_and_advice: "Toon microlearnings",
  slow_insights: "Slow",
  slow_time: "Slow time",
  social_media: "Social media",
  sometimes: "Soms",
  something_went_wrong: "Er is iets misgegaan",
  sort_by: "Sorteer op",
  source_report: "Bronrapportage",
  specific_app_templates: "Specifieke sjablonen voor app gebruikers",
  spectator: "Toeschouwer",
  start_date: "Startdatum",
  starting_point: "Uitgangspunt",
  starting_points: "Uitgangspunten",
  stimulative: "Stimulerend",
  subcategories: "Subcategorieën",
  submit: "Verzend",
  successfully_created: (subject = "model") =>
    `${subject} succesvol aangemaakt`,
  successfully_saved: (subject = "model") => `${subject} succesvol opgeslagen`,
  successfully_deleted: (subject = "model") =>
    `${subject} succesvol verwijderd`,
  supporting_indicators: "Ondersteunende indicatoren",
  reflector: "Reflector",
  reflector_details: "Reflector details",
  reflector_ending: "Reflector eindtekst",
  reflector_intro: "Reflector intro",
  reflector_participant_ending: "Reflector gesprekspartner eindtekst",
  reflector_participant_intro: "Reflector gesprekspartner intro",
  reflectors: "Reflectors",
  teaching_aid: "Leerhulpmiddel",
  teaching_aids: "Leerhulpmiddelen",
  team: "Team",
  team_report: "Team rapportage",
  teams: "Teams",
  template: "Sjabloon",
  templates: "Sjablonen",
  text: "Tekst",
  theme: "Thema",
  timeline: "Tijdlijn",
  title: "Titel",
  top_report: "Top rapportage",
  total_summary_report: "Totaal samenvatting rapportage",
  training: "Training",
  trial_reflector: "Proef reflector",
  type: "Type",
  unanswered: "Onbeantwoord",
  update_organisation_invites: "Organisatie uitnodigingen aanpassen",
  url: "Url",
  user: "Gebruiker",
  user_score: "Deelnemerscore",
  username: "Gebruikersnaam",
  user_details: "Gebruiker details",
  user_did_not_finish_a_reflector:
    "De deelnemer heeft één van de gekozen reflectors niet ingevuld",
  users: "Gebruikers",
  value: "Waarde",
  video: "Video",
  visualize_result_types_by: "Visualiseer resultaat types o.b.v.",
  website: "Website",
  yes: "Ja",
  you_need_to_select_at_least_a_team_or_a_user:
    "Je moet op zijn minst één team of deelnemer selecteren",
};
