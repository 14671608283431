import { IJourneyTeachingAid } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";
import { journeyTeachingAidTypeLabels } from "../../../constants/journey-teaching-aid-type-labels";

interface IProps {
  journeyTeachingAid: IJourneyTeachingAid;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const JourneyTeachingAidRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD>{props.journeyTeachingAid.order}</TD>
    <TD>{props.journeyTeachingAid.teachingAid?.title}</TD>
    <TD>{props.journeyTeachingAid.awardedPoints}</TD>
    <TD>{journeyTeachingAidTypeLabels[props.journeyTeachingAid.type]}</TD>
  </StyledTR>
);
