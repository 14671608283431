import { IJourney } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";

interface IProps {
  model: IJourney;
}

export const JourneyOption: FC<IProps> = (props) => (
  <div title={props.model.description}>{props.model.title}</div>
);
