import { formatDate } from "../../utilities/date.utils";

export const en = {
  action_and_advice_buttons: "Action and advice buttons",
  active: "Active",
  add_button: "Add button",
  add_journey_teaching_aid: "Add journey teaching aid",
  add_new_user: "Add new user",
  add_question: "Add question",
  add_section: "Add section",
  address: "Address",
  administrator: "Administrator",
  advice: "Advice",
  advice_question: "Advice question",
  advice_question_placeholder: "I {answer} think I'm doing a good job",
  always: "Always",
  and: "and",
  answer: "Answer",
  answerMethod: "Answer method",
  answers: "Answers",
  app_intro_without_salutation: "App intro (without salutation)",
  article: "Article",
  ascending: "Ascending",
  attitude: "Attitude",
  avg: "Avg.",
  available_to_other_experts: "Available to other experts",
  awarded_points: "Awarded points",
  back: "Back",
  background_information: "Background information",
  base_statements_on_reflector: "Base statements on reflector",
  behaviour: "Behaviour",
  biography: "Biography",
  birthdate: "Birthdate",
  brand_logo: "Brand logo",
  categories: "Categories",
  category: "Category",
  category_chart_report: "Category chart report",
  category_summary_report: "Category summary report",
  center_description: "Center description",
  cherish_and_starting_points: "To cherish",
  cherish_point: "Cherish point",
  cherish_points: "Cherish points",
  choose_invites: "Choose invites",
  choose_report_template: "Choose a template for the report",
  choose_base_template: "Choose base template",
  city: "City",
  classification: "Classification",
  code: "Code",
  compare_with_preceeding: "Compare with preceeding",
  comparison_report: "Comparison report",
  confirm_deletion: "Are you sure you want to delete this?",
  confirm_importing_x_users: (userCount: number) =>
    `Are you sure you want to import ${userCount} users?`,
  confirm_resend_mail: "Are you sure you want to resend the invitation mail?",
  confirm_send_reminder: "Are you sure you want to send a reminder mail?",
  confirm_reset_password: "Are you sure you want to reset this users password?",
  constructive: "Constructive",
  contact: "Contact",
  content: "Content",
  context_codes: "Context codes",
  correct_answer: "Correct answer",
  create_category: "Create category",
  create_journey: "Create journey",
  create_genre: "Create genre",
  create_invite: "Create invite",
  create_invite_link: "Create invite link",
  invite_links: "Invite links",
  create_motivation: "Create motivation",
  create_news: "Create news",
  create_organisation: "Create organisation",
  create_report: "Create report",
  create_reflector: "Create reflector",
  create_reward: "Create reward",
  create_teaching_aid: "Create teaching aid",
  create_team: "Create team",
  create_user: "Create user",
  created_at: "Created at",
  currently_loading: "Currently loading",
  date_format: "YYYY",
  date_format_string: "yyyy-mm-dd",
  deadline: "Deadline",
  default: "Default",
  delete: "Delete",
  delete_template: "Delete template",
  derivative: "Derivative",
  destructive: "Destructive",
  description: "Description",
  descending: "Descending",
  development_advice: "Development advice",
  development_and_obstacle_points: "To develop",
  development_capacity: "Development capacity",
  development_point: "Development point",
  development_points: "Development points",
  development_potential: "Development Potential",
  development_space: "Development space",
  direct_invite: "Direct invite",
  direction: "Direction",
  discussion_report: "Discussion report",
  discussion_reports_arent_compatible_with_team_reports:
    "Discussion reports aren't compatible with team reports",
  dot_report: "Dot report",
  download: "Download",
  download_selection: "Download selection",
  dutch: "Dutch",
  learning_duration_minutes: "Duration in minutes",
  edit: "Edit",
  edit_answer: "Edit answer",
  edit_category: "Edit category",
  edit_genre: "Edit genre",
  edit_invite: "Edit invite",
  edit_journey: "Edit journey",
  edit_journey_teaching_aid: "Edit journey teaching aid",
  edit_motivation: "Edit motivation",
  edit_news: "Edit news",
  edit_organisation: "Edit organisation",
  edit_question: "Edit question",
  edit_report: "Edit report",
  edit_section: "Edit section",
  edit_reflector: "Edit reflector",
  edit_reward: "Edit reward",
  edit_teaching_aid: "Edit teaching aid",
  edit_team: "Edit team",
  edit_template: "Edit template",
  edit_user: "Edit user",
  email: "Email",
  email_without_salutation_and_closing:
    "Email (without salutation and closing)",
  enable_reports: "Enable reports",
  end_date: "End date",
  english: "English",
  expert: "Expert",
  experts: "Experts",
  experts_or_spectators: "Experts/Spectators",
  explanation: "Explanation",
  expression: "Expression",
  feeling: "Feeling",
  female: "Female",
  file: "File",
  filter_on_category: "Filter on category",
  filter_on_genre: "Filter on genre",
  filter_on_classification: "Filter on classification",
  filter_on_label: "Filter on label",
  filter_on_result_type: "Filter on participant result type",
  filter_on_partner_result_type: "Filter on partner/team result type",
  filters: "Filters",
  firstName: "First name",
  force_new_page: "Force new page",
  gender: "Gender",
  generate: "Generate",
  generate_report: "Generate report",
  generic_report: "Generic report",
  genre: "Genre",
  genres: "Genres",
  go_a_step_back: "Go a step back",
  he: "He",
  her: "Her",
  highlight_report: "Highlight report",
  him: "Him",
  his: "His",
  image: "Image",
  import_users: "Import users",
  in_name_of_expert: "In the name of expert",
  incidentally: "Rarely",
  include_in_report: "Include in report",
  incorrect_credentials: "Incorrect username/password combination",
  indicator: "Indicator",
  indicator_report: "Indicator report",
  individual_persons: "Individual persons",
  information_buttons: "Information buttons",
  internal_use: "Internal use",
  invitation_email_without_salutation_and_closing:
    "Invitation mail without salutation and closing",
  invite: "Invite",
  invites_sent: "Invites sent",
  is_hidden_on_print: "Is hidden on print",
  is_team_assignment: "Is team assignment",
  is_public: "Is public",
  journey: "Journey",
  journey_details: "Journey details",
  journey_teaching_aid: "Journey teaching aid",
  journeys: "Journeys",
  label: "Label",
  language: "Language",
  lastName: "Last name",
  legend: "Legend",
  legend_extended: "Legend (extended)",
  LID: "LID",
  login: "Login",
  logout: "Logout",
  mail_partners: "Mail partners",
  mail_successfully_sent: "Mail successfully sent",
  male: "Male",
  manual: "Manual",
  maximum_number_of_questions: "Maximum number of questions",
  microlearning: "Microlearning",
  microlearnings: "Microlearnings",
  microlearning_footer: (fullName: string, date: Date) =>
    `Slow microlearning for ${fullName} - Generated on ${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`,
  minimal_deviation_between_invitee_and_partner_answer:
    "Minimal deviation between invitee and partner answer (%)",
  mostly: "Often",
  motivation: "Motivation",
  name: "Name",
  never: "Never",
  new: "New",
  new_page_after: "New page after",
  new_page_before: "New page before",
  new_template: "New template",
  news: "News",
  news_item: "News item",
  no: "No",
  no_results_found: "No results found",
  no_valid_embedded_video_url: "No valid embedded video url",
  number_of_participants: "Number of participants",
  obstacle_point: "Obstacle point",
  obstacle_points: "Obstacle points",
  obstructive: "Obstructive",
  omit_questions_occurring_in_previous_section:
    "Omit questions occurring in the previous section",
  open: "Open",
  options: "Options",
  order: "Order",
  organisation: "Organisation",
  organisation_details: "Organisation details",
  oganisation_view: "Organisation view",
  organisations: "Organisations",
  other: "Other",
  overview_report: "Overview report",
  overwrite_template: "Overwrite template",
  participant: "Participant",
  participants: "Participants",
  partner_advice_question: "Partner advice question",
  partner_advice_question_placeholder:
    "{name} {answer} thinks {refer} is doing a good job",
  partner_code: "Partner code",
  partner_motivation: "Partner motivation",
  partner_question: "Partner question",
  partner_question_placeholder: "{name} thinks {refer} is doing a good job",
  partner_score: "Partner score",
  partner: "Partner",
  partner__team: "Partner/team",
  partner__team_score: "Partner/team score",
  partners: "Partners",
  partners_remaining: "Partner(s) remaining",
  password: "Password",
  password_has_been_reset: "The users password has been reset",
  personal_top_report: "Top report (user score)",
  phone_number: "Phone number",
  postal_code: "Postal code",
  primary_color: "Primary color",
  prioritize_question: "Prioritize question",
  private_template: "Private template",
  prizeCabinet: "Prize cabinet",
  profile_picture: "Profile picture",
  publicly_available_for_users: "Publicly available for users",
  question: "Question",
  question_placeholder: "I think I'm doing a good job",
  question_summary_report: "Question summary report",
  question_type: "Question type",
  question_type_constructive_destructive:
    "Question type (constructive, destructive)",
  questions: "Statements",
  quiz: "Quiz",
  report: "Report",
  report_requests: "Report requests",
  reports: "Reports",
  resend_invitation: "Resend invitation",
  reset_password: "Reset password",
  role: "Role",
  save_as_new_template: "Save as new template",
  save_template: "Save template",
  search: "Search",
  search_label: "Search label",
  select_a_reflector: "Select a reflector",
  select_an_organisation: "Select an organisation",
  send: "Send",
  send_reminder: "Send reminder",
  send_report: "Send report",
  share_the_following_url_with_potential_users:
    "Share the following url with potential users",
  she: "She",
  show_access_code_to_user: "Show access code to user",
  show_indicator_during_reflector: "Show indicator during reflector",
  show_participants_during_reflector: "Show participants during reflector",
  show_participant_results_to_user: "Show participant results to user",
  show_action_and_advice: "Show microlearnings",
  slow_insights: "Slow",
  slow_time: "Slow time",
  social_media: "Social media",
  something_went_wrong: "Something went wrong",
  sometimes: "Sometimes",
  sort_by: "Sort by",
  source_report: "Souce report",
  specific_app_templates: "Specific app templates",
  spectator: "Spectator",
  start_date: "Start date",
  starting_point: "Starting point",
  starting_points: "Starting points",
  stimulative: "Stimulative",
  subcategories: "Subcategories",
  submit: "Submit",
  successfully_created: (subject = "model") =>
    `${subject} was successfully created`,
  successfully_saved: (subject = "model") =>
    `${subject} was successfully saved`,
  successfully_deleted: (subject = "model") =>
    `${subject} was successfully deleted`,
  supporting_indicators: "Supporting indicators",
  reflector: "Reflector",
  reflector_details: "Reflector details",
  reflector_ending: "Reflector ending",
  reflector_intro: "Reflector intro",
  reflector_participant_ending: "Reflector participant ending",
  reflector_participant_intro: "Reflector participant intro",
  reflectors: "Reflectors",
  report_footer: (subjectName: string, organisationName: string, date: Date) =>
    `Slow report for ${subjectName} (${organisationName}) - Generated on ${formatDate(
      date,
      "YYYY-MM-DD"
    )}`,
  reward: "Reward",
  reward_image: "Reward image",
  rewarded_points: "Rewarded points",
  rewards: "Rewards",
  required_points: "Required points",
  requires_partner_reviews: "Requires partner reviews",
  teaching_aid: "Teaching aid",
  teaching_aids: "Teaching aids",
  team: "Team",
  team_report: "Team report",
  teams: "Teams",
  template: "Template",
  templates: "Templates",
  text: "Text",
  theme: "Theme",
  timeline: "Timeline",
  title: "Title",
  top_report: "Top report",
  total_summary_report: "Total summary report",
  training: "Training",
  trial_reflector: "Trial reflector",
  type: "Type",
  unanswered: "Unanswered",
  update_organisation_invites: "Update organisation invites",
  url: "Url",
  user: "User",
  user_score: "User score",
  user_did_not_finish_a_reflector:
    "The user did not finish one of the reflectors",
  username: "Username",
  user_details: "User details",
  users: "Users",
  value: "Value",
  video: "Video",
  visualize_result_types_by: "Visualize result types by",
  website: "Website",
  yes: "Yes",
  you_need_to_select_at_least_a_team_or_a_user:
    "You need to select at least a team or a user",
};
