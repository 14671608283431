import { IReward } from "@hulanbv/ssllow-packages";
import { CrudService } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<IReward> {
  constructor() {
    super([environment.REACT_APP_API_URL, "reward"].join("/"), httpService);
  }
}

export const rewardService = new Service();
