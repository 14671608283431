import { useMemo } from "react";
import React from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";

interface IProps {
  src: string;
}

interface IUrlConversion {
  hostnames: string[];
  convert: (url: URL) => string;
}

const urlConversions: IUrlConversion[] = [
  {
    hostnames: ["vimeo.com"],
    convert: (url: URL) =>
      `https://player.vimeo.com/video/${url.pathname.split("/")[1]}?h=${
        url.pathname.split("/")[2] ?? ""
      }`,
  },
  {
    hostnames: ["youtube.com", "www.youtube.com"],
    convert: (url: URL) =>
      `https://www.youtube.com/embed/${url.searchParams.get("v")}?autoplay=1`,
  },
  {
    hostnames: ["youtu.be"],
    convert: (url: URL) =>
      `https://www.youtube.com/embed/${url.pathname.split("/")[1]}?autoplay=1`,
  },
];

function EmbeddedVideoPlayerElement(props: IProps): JSX.Element {
  const { src } = props;

  const convertedUrl = useMemo(() => {
    try {
      const url = new URL(src);
      const applicableUrlConversion = urlConversions.find((urlConversion) =>
        urlConversion.hostnames.includes(url.hostname)
      );
      return applicableUrlConversion?.convert(url);
    } catch (error) {
      return undefined;
    }
  }, [src]);

  if (convertedUrl === undefined) {
    return <p>{dictionary.no_valid_embedded_video_url}</p>;
  }

  return (
    <div className={styles.container}>
      <iframe
        src={convertedUrl}
        title="Embedded Video"
        allow={["accelerometer", "autoplay"].join(";")}
      />
    </div>
  );
}

const styles = {
  container: style({
    position: "relative",
    paddingBottom: "56.25%",
    width: "100%",
    background: "rgb(137, 137, 137)",
    boxShadow: "0 10px 10px rgba(0, 0, 0, 0.1)",
    $nest: {
      iframe: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: 0,
        zIndex: 10,
      },
    },
  }),
};

export { EmbeddedVideoPlayerElement };
