import { FC, useEffect, useState } from "react";
import { IViewProps } from "../interfaces/view-props.interface";
import { journeyTeachingAidCompletionService } from "../services/journey-teaching-aid-completion.service";
import React from "react";
import {
  IJourneyTeachingAidCompletion,
  ITeam,
  IUser,
} from "@hulanbv/ssllow-packages";
import { JourneyTeachingAid } from "../components/elements/journey-teaching-aid.element";
import { UserOption } from "../components/controls/option-templates/user-option";
import { Search } from "../components/controls/search";
import { dictionary } from "../constants/i18n/dictionary";
import { organisationService } from "../services/organisation.service";
import { TeamOption } from "../components/controls/option-templates/team-option";
import { FormRow } from "../components/forms/form-row";
import { style } from "typestyle";

export const OrganisationTimelineView: FC<IViewProps> = (props) => {
  const [completionData, setCompletionData] = useState<
    IJourneyTeachingAidCompletion[]
  >([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedTeamId) {
        const { data: journeyTeachingAidCompletion } =
          await journeyTeachingAidCompletionService.getTeachingAidCompletionsByTeamId(
            selectedTeamId,
            {
              ...(selectedUserId ? { filter: { userId: selectedUserId } } : {}),
              populate: [
                "journeyTeachingAid.teachingAid.journey",
                "asset",
                "user",
              ],
              sort: ["-createdAt"],
            }
          );
        setCompletionData(journeyTeachingAidCompletion);
      }

      if (!selectedTeamId) {
        const { data: journeyTeachingAidCompletion } =
          await journeyTeachingAidCompletionService.getTeachingAidCompletionsByOrganisationId(
            props.routing.params.id,
            {
              ...(selectedUserId ? { filter: { userId: selectedUserId } } : {}),
              populate: [
                "journeyTeachingAid.teachingAid.journey",
                "asset",
                "user",
              ],
              sort: ["-createdAt"],
            }
          );

        setCompletionData(journeyTeachingAidCompletion);
      }

      const { data: organisation } = await organisationService.get(
        props.routing.params.id,
        {
          populate: ["teams", "members"],
        }
      );
      setUsers(organisation.members ?? []);
      setTeams(organisation.teams ?? []);
    };
    fetchData();
  }, [props.routing.params.id, selectedTeamId, selectedUserId]);

  return (
    <div className={styles.container}>
      <FormRow
        attributes={{
          className: styles.row,
        }}
      >
        <Search<ITeam>
          keyField="id"
          label={dictionary.teams}
          find={async (search) =>
            teams.filter((team) =>
              JSON.stringify(team).toLowerCase().includes(search.toLowerCase())
            )
          }
          onChange={(selection) => setSelectedTeamId(selection[0])}
          optionTemplate={TeamOption}
          disabled={teams.length === 0}
          openOnFocus
        />
        <Search<IUser>
          keyField="id"
          label={dictionary.individual_persons}
          find={async (search) =>
            users.filter((user) =>
              JSON.stringify(user).toLowerCase().includes(search.toLowerCase())
            )
          }
          optionTemplate={UserOption}
          onChange={(selection) => setSelectedUserId(selection[0])}
          disabled={users.length === 0}
          openOnFocus
        />
      </FormRow>
      {completionData.map((completion) => (
        <JourneyTeachingAid
          journeyTrainingAid={completion.journeyTeachingAid}
          JourneyTeachingAidCompletion={completion}
        />
      ))}
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  }),
  row: style({
    width: "50%",
  }),
};
