/* eslint-disable react-hooks/exhaustive-deps */
import { IModel } from "@hulanbv/ssllow-packages";
import {
  CrudService,
  IHttpOptions,
  IResponse,
  optionsToParams,
} from "nest-utilities-client";
import React, { ReactNode, useEffect, useState } from "react";
import { style } from "typestyle";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFilter } from "../../interfaces/filter.interface";
import { DropdownButton } from "../controls/dropdown-button";
import { Input } from "../controls/input";
import { FilterOption } from "../controls/option-templates/filter-option";
import { Pagination } from "../controls/pagination";
import { Label } from "../typography/label";
import { ITableProps, Table } from "./table";

interface IProps<ModelType = IModel>
  extends Omit<ITableProps<ModelType>, "items"> {
  service: CrudService<ModelType>;
  options?: IHttpOptions;
  filters?: IFilter[];
  hidePagination?: boolean;
  customControls?: ReactNode;
  customFind?: (options: IHttpOptions) => Promise<IResponse<IModel[]>>;
}

export function CrudTable<ModelType>(
  props: IProps<ModelType>
): React.ReactElement {
  const [isInitiated, setIsInitiated] = useState<boolean>(false);
  const [models, setModels] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [limit /*, setLimit */] = useState<number>(props.options?.limit || 10);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<IFilter[] | null>(null);
  const [activeFilters, setActiveFilters] = useState<
    Record<string, string | number>
  >({});
  const [filterCount, setFilterCount] = useState<number>(0);
  const [sortKey, setSortKey] = useState<string>(
    props.sortKey || Object.keys(props.headings)[0] || ""
  );
  const [sortAscending, setSortAscending] = useState<boolean>(
    props.sortAscending ?? true
  );

  // handle click on the table head
  const onHeadClick = (key: string) => {
    setSortAscending(key === sortKey ? !sortAscending : true);
    setSortKey(key);

    props.onHeadClick?.(key);
  };

  // handle a change in the search input
  const onSearchChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setPage(1); // reset to the first page
    setSearch(event.currentTarget.value);
  };

  const onFilterChange = (filter: IFilter, value?: (string | number)[]) => {
    setActiveFilters((activeFilters) => {
      const _activeFilters = { ...activeFilters };
      if (isInitiated && value?.[0] !== _activeFilters[filter.key]) {
        setPage(1);
      }
      if (value?.length) {
        _activeFilters[filter.key] = value?.[0];
      } else {
        delete _activeFilters[filter.key];
      }
      setFilterCount(Object.keys(_activeFilters).length);
      return _activeFilters;
    });
  };

  useEffect(() => {
    (async () => {
      const filters = props.filters?.map((filter) => ({ ...filter })) ?? [];
      for (const filter of filters) {
        const _onChange = filter.searchProps?.onChange;
        if (filter.searchProps) {
          filter.searchProps.onChange = (selection) => {
            _onChange?.(selection);
            onFilterChange(filter, selection);
          };
        }
      }

      const urlSearch = new URLSearchParams(window.location.search);
      const params = Array.from(urlSearch.entries());
      for (const [key, value] of params) {
        if (key === "search") {
          setSearch(value);
        } else if (key === "offset") {
          setPage(Math.floor(+value / limit + 1));
        } else if (key === "sort") {
          setSortKey(value.split(",")[0].replace("-", ""));
          setSortAscending(!value.startsWith("-"));
        } else if (key.startsWith("filter")) {
          const filterKey = key.replace("]", "").split("[")[1];
          const filter = filters?.find((filter) => filter.key === filterKey);
          if (filter?.searchProps) {
            filter.searchProps.defaultValue = await filter.searchProps.find(
              value
            );

            filter.searchProps.onChange?.([value]);
          }
        }
      }

      setFilters(filters);
      setIsInitiated(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, props.filters]);

  useEffect(() => {
    if (!isInitiated) {
      return;
    }
    const collectModels = async () => {
      setIsLoading(true);
      try {
        const filter: Record<string, any> = {
          ...props.options?.filter,
        };
        for (const [key, value] of Object.entries(activeFilters)) {
          const propFilter = props.filters?.find(
            (filter) => filter.key === key
          );
          if (!propFilter?.excludeFromOptions) {
            filter[key] = value;
          }
        }

        const options: IHttpOptions = {
          ...props.options,
          sort: [(sortAscending ? "" : "-") + sortKey, "_id"],
          offset: (page - 1) * limit,
          filter,
          limit,
          search,
        };

        const savedOptions = {
          ...options,
          filter: { ...props.options?.filter, ...activeFilters },
        };
        delete savedOptions.searchScope;
        delete savedOptions.limit;
        delete savedOptions.populate;

        const params = optionsToParams(savedOptions);
        window.history.replaceState(
          undefined,
          document.title,
          window.location.origin +
            window.location.pathname +
            "?" +
            params.join("&")
        );
        const response = await (props.customFind?.(options) ??
          props.service.getAll(options));

        setTotalCount(+(response.headers.get("X-total-count") || 0));
        setModels(response.data);
      } catch {}

      setIsLoading(false);
    };

    collectModels();
  }, [
    page,
    limit,
    search,
    sortKey,
    sortAscending,
    props.service,
    props.options,
    props.customFind,
    isInitiated,
    activeFilters,
    props.filters,
  ]);

  return (
    <>
      <div className={styles.controls}>
        <div className={styles.parameters}>
          <Input
            label={dictionary.search}
            attributes={{
              defaultValue: search,
              placeholder: dictionary.search,
              onKeyUp: onSearchChange,
            }}
          />
          {!!filters?.length && (
            <div style={{ marginLeft: 10 }}>
              <Label>&nbsp;</Label>
              <DropdownButton<IFilter>
                label={`${
                  dictionary.filters
                } (${filterCount} ${dictionary.active.toLowerCase()})`}
                options={filters}
                optionTemplate={FilterOption}
                disableStyles
              />
            </div>
          )}
        </div>
        <div className={styles.customControls}>{props.customControls}</div>
      </div>
      <Table
        headings={props.headings}
        customHeadRow={props.customHeadRow}
        customRow={props.customRow}
        onHeadClick={onHeadClick}
        onRowClick={props.onRowClick}
        items={models}
        sortAscending={sortAscending}
        sortKey={sortKey}
      />

      {!models.length && (
        <div className={styles.emptyRow}>
          {isLoading
            ? `${dictionary.currently_loading}...`
            : dictionary.no_results_found}
        </div>
      )}
      <div className={styles.bottomControls}>
        {!props.hidePagination && models.length > 0 && (
          <Pagination
            onPageChange={setPage}
            page={page}
            totalPages={Math.max(1, Math.ceil(totalCount / limit))}
          />
        )}
      </div>
    </>
  );
}

const styles = {
  controls: style({
    alignSelf: "flex-end",
    display: "flex",
    marginBottom: 30,
    $nest: {
      "&>*": {
        margin: "0 5px",
      },
    },
  }),
  parameters: style({
    display: "flex",
  }),
  bottomControls: style({
    margin: "30px 0",
  }),
  customControls: style({
    alignSelf: "flex-end",
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  }),
  emptyRow: style({
    padding: 30,
    textAlign: "center",
    opacity: 0.6,
  }),
};
