import { Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { style } from "typestyle";
import { Card } from "../components/containers/card";
import { CrudForm } from "../components/forms/crud-form";
import { routes } from "../components/routing/routes";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { satisfiesRoles } from "../utilities/satisfies-roles";
import { RewardForm } from "../components/forms/reward-form";
import { rewardService } from "../services/reward.service";

export const RewardEditView: FC<IViewProps> = (props) => (
  <Card attributes={{ className: styles.card }}>
    <CrudForm
      messageSubject={dictionary.reward}
      form={RewardForm}
      modelId={props.routing.params.rewardId}
      model={{ organisationId: props.routing.params.id }}
      redirectPath={routes.organisationRewards.path.replace(
        ":id",
        props.routing.params.id
      )}
      service={rewardService}
      fetchOptions={{ populate: ["organisation", "asset"] }}
      readOnly={!satisfiesRoles(Role.ADMIN, Role.EXPERT)}
    />
  </Card>
);

const styles = {
  card: style({
    maxWidth: 550,
    margin: "0 auto",
  }),
};
