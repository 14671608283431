import { IJourney, IOrganisation } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Search } from "../controls/search";
import { Form } from "./form";
import { FormRow } from "./form-row";
import { Textarea } from "../controls/textarea";
import { organisationService } from "../../services/organisation.service";
import { OrganisationOption } from "../controls/option-templates/organisation-option";

export const JourneyForm: FC<IFormProps<IJourney>> = (props) => {
  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />

      <FormRow>
        <Input
          label={dictionary.title}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.title,
            name: "title",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Textarea
          label={dictionary.description}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.description,
            name: "description",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Search<IOrganisation>
          keyField="id"
          label={dictionary.organisation}
          defaultValue={
            props.model?.organisation ? [props.model?.organisation] : []
          }
          find={async (search) =>
            (
              await organisationService.getAll({
                search,
                searchScope: ["name"],
                sort: ["name"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={OrganisationOption}
          disabled={props.disabled}
          name="organisationId"
          openOnFocus
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
