import { IJourney, Role } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { Router } from "react-history-router";
import { Button } from "../components/controls/button";
import { routes } from "../components/routing/routes";
import { CrudTable } from "../components/tables/crud-table";
import { dictionary } from "../constants/i18n/dictionary";
import { IViewProps } from "../interfaces/view-props.interface";
import { satisfiesRoles } from "../utilities/satisfies-roles";
import { journeyService } from "../services/journey.service";
import { JourneyRow } from "../components/tables/custom-rows/journey-row";

export const JourneysView: FC<IViewProps> = (props) => (
  <CrudTable<IJourney>
    service={journeyService}
    sortKey="createdAt"
    options={{ populate: ["organisation"] }}
    headings={{
      title: dictionary.title,
      organisation: dictionary.organisation,
      createdAt: dictionary.created_at,
    }}
    customRow={(journey) => (
      <JourneyRow
        key={journey.id}
        journey={journey}
        attributes={{
          onClick: () =>
            Router.push(routes.journeyDetails.path, {
              id: journey.id,
            }),
        }}
      />
    )}
    customControls={
      satisfiesRoles(Role.ADMIN, Role.EXPERT) && (
        <Button
          attributes={{
            onClick: () => Router.push(routes.createJourney.path),
          }}
          appearance={"primary"}
        >
          {dictionary.new}
        </Button>
      )
    }
  />
);
