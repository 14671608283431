import { IJourney, ITeam, ITeamJourney, IUser } from "@hulanbv/ssllow-packages";
import React, { FC, useCallback, useEffect, useState } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { userService } from "../../services/user.service";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { UserOption } from "../controls/option-templates/user-option";
import { Search } from "../controls/search";
import { Form } from "./form";
import { FormRow } from "./form-row";
import { journeyService } from "../../services/journey.service";
import { JourneyOption } from "../controls/option-templates/journey-option";
import { teamJourneyService } from "../../services/team-journey.service";

export const TeamForm: FC<IFormProps<ITeam>> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTeamJourney, setSelectedTeamJourney] = useState<
    ITeamJourney[]
  >([]);
  const [selectedJourneyIds, setSelectedJourneyIds] = useState<string[]>([]);

  const organisationId = window.location.pathname.split("/")[2];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: teamJourneys } = await teamJourneyService.getAll({
          filter: { teamId: props.model?.id },
          populate: ["journey"],
        });

        setSelectedTeamJourney(teamJourneys);
        setSelectedJourneyIds(
          teamJourneys.map((teamJourney) => teamJourney.journey?.id)
        );
        setIsLoading(false);
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, [props.model]);

  const onSubmit = useCallback(
    async (formData: FormData, model: ITeam) => {
      // Collect all current journey IDs from selectedTeamJourney.
      const currentJourneyIds = selectedTeamJourney.map(
        (teamJourney) => teamJourney.journey?.id
      );

      // Check for differences between the selected journey IDs and the current journey IDs.
      const hasDifferences =
        selectedJourneyIds.some((id) => !currentJourneyIds.includes(id)) ||
        currentJourneyIds.some((id) => !selectedJourneyIds.includes(id));

      // Use `hasDifferences` to decide when to update your team journeys.
      if (hasDifferences) {
        // Handle additions or replacements.
        const newJourneyIds = selectedJourneyIds.filter(
          (id) => !currentJourneyIds.includes(id)
        );
        const newTeamJourneys = newJourneyIds.map((journeyId) => ({
          journeyId: journeyId,
          teamId: props.model?.id,
        }));

        if (newTeamJourneys.length > 0) {
          await Promise.all(
            newTeamJourneys.map((teamJourney) =>
              teamJourneyService.post(teamJourney)
            )
          );
        }

        // Handle removals.
        const removedJourneyIds = currentJourneyIds.filter(
          (id) => !selectedJourneyIds.includes(id)
        );

        if (removedJourneyIds.length > 0) {
          await Promise.all(
            removedJourneyIds.map((journeyId) => {
              const teamJourneyId = selectedTeamJourney.find(
                (teamJourney) => teamJourney.journey?.id === journeyId
              )?.id;
              return teamJourneyService.delete(teamJourneyId);
            })
          );
        }
      }

      props.onSubmit?.(formData, model);
    },
    [props.model, props.onSubmit, selectedJourneyIds, selectedTeamJourney]
  );

  return (
    <Form
      {...props}
      onSubmit={(formData: FormData, model: ITeam) => onSubmit(formData, model)}
    >
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />

      <FormRow>
        <Input
          label={dictionary.name}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.name,
            name: "name",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Search<IUser>
          keyField="id"
          label={dictionary.contact}
          defaultValue={props.model?.expert ? [props.model?.expert] : []}
          find={async (search) =>
            (
              await userService.getAll({
                search,
                sort: ["firstName"],
                limit: 25,
              })
            ).data
          }
          optionTemplate={UserOption}
          disabled={props.disabled}
          name="expertId"
          required
        />
      </FormRow>
      {isLoading === false && props.model && (
        <FormRow>
          <Search<IJourney>
            keyField="id"
            label={dictionary.journey}
            defaultValue={selectedTeamJourney.map(
              (teamJourney) => teamJourney.journey as IJourney
            )}
            find={async (search) =>
              (
                await journeyService.getAll({
                  filter: { organisationId: organisationId },
                  search,
                  sort: ["title"],
                  limit: 25,
                })
              ).data
            }
            optionTemplate={JourneyOption}
            onChange={(selection: string[]) => {
              setSelectedJourneyIds(selection);
            }}
            openOnFocus
            multiple
            disabled={props.disabled}
          />
        </FormRow>
      )}

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
