import {
  IJourneyTeachingAid,
  IJourneyTeachingAidCompletion,
} from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";
import neutralLabelBackground from "../../assets/svg/backgrounds/neutral-label.svg";
import { FloatingCard } from "../containers/floating-card";
import { H1 } from "../typography/h1";
import { dictionary } from "../../constants/i18n/dictionary";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  journeyTrainingAid?: IJourneyTeachingAid;
  JourneyTeachingAidCompletion?: IJourneyTeachingAidCompletion;
}

export const JourneyTeachingAid: FC<IProps> = (props) => {
  return (
    <FloatingCard
      attributes={{
        ...props.attributes,
        className: classes(props.attributes?.className, styles.teachingAid),
      }}
    >
      {props.JourneyTeachingAidCompletion?.createdAt && (
        <div className={styles.completedRibbon}>
          Afgerond op{" "}
          {new Date(
            props.JourneyTeachingAidCompletion?.createdAt
          ).toLocaleDateString("nl-NL")}
        </div>
      )}
      <H1 attributes={{ className: styles.h2 }}>
        {props.journeyTrainingAid?.teachingAid?.title}
      </H1>

      {props.JourneyTeachingAidCompletion?.asset?.path && (
        <img
          src={props.JourneyTeachingAidCompletion?.asset?.path}
          className={styles.image}
          alt=""
        />
      )}

      {props.JourneyTeachingAidCompletion?.answer && (
        <p className={styles.paragraph}>
          {props.JourneyTeachingAidCompletion?.answer}
        </p>
      )}

      <div className={styles.floatingCardFooter}>
        <p>
          {props.journeyTrainingAid?.journey?.title} -{" "}
          {props.JourneyTeachingAidCompletion?.user?.fullName}
        </p>
        <p>
          {dictionary.rewarded_points}:{" "}
          {props.journeyTrainingAid?.awardedPoints}
        </p>
      </div>
    </FloatingCard>
  );
};

const styles = {
  teachingAid: style({
    pageBreakInside: "avoid",
    padding: 30,
    margin: "40px 0",
    position: "relative",
  }),
  image: style({
    maxWidth: "100%",
    height: "auto",
    borderRadius: 10,
    marginTop: 20,
  }),
  h2: style({
    fontSize: "1.12rem",
    color: "#000",
    fontWeight: 500,
    paddingBottom: 0,
  }),
  paragraph: style({
    fontSize: "1rem",
    color: "#000",
    fontWeight: 400,
    paddingBottom: 0,
  }),
  floatingCardFooter: style({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 20,
    fontSize: "0.8rem",
    color: "#000",
    opacity: 0.75,
    fontWeight: 400,
    paddingBottom: 0,
  }),
  completedRibbon: style({
    background: `url(${neutralLabelBackground})`,
    color: "white !important",
    backgroundSize: "180px 40px",
    minWidth: 180,
    minHeight: 40,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: 30,
    top: -20,
  }),
};
