import { IJourney } from "@hulanbv/ssllow-packages";
import { CrudService } from "nest-utilities-client";
import { environment } from "../constants/environment.constant";
import { httpService } from "./http.service";

class Service extends CrudService<IJourney> {
  constructor() {
    super([environment.REACT_APP_API_URL, "journey"].join("/"), httpService);
  }
}

export const journeyService = new Service();
