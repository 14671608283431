import { IJourney } from "@hulanbv/ssllow-packages";
import React, { FC, HTMLAttributes } from "react";
import { StyledTR } from "../styled-table-row";
import { TD } from "../table-data";
import { formatDate } from "../../../utilities/date.utils";
import { dictionary } from "../../../constants/i18n/dictionary";

interface IProps {
  journey: IJourney;
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const JourneyRow: FC<IProps> = (props) => (
  <StyledTR attributes={props.attributes}>
    <TD>{props.journey.title}</TD>
    <TD>{props.journey.organisation?.name}</TD>
    <TD>
      {formatDate(props.journey.createdAt!.toString(), dictionary.date_format)}
    </TD>
  </StyledTR>
);
