import { IReward } from "@hulanbv/ssllow-packages";
import React, { FC } from "react";
import { dictionary } from "../../constants/i18n/dictionary";
import { IFormProps } from "../../interfaces/form-props.interface";
import { Button } from "../controls/button";
import { Input } from "../controls/input";
import { Form } from "./form";
import { FormRow } from "./form-row";
import { Textarea } from "../controls/textarea";
import { AssetUpload } from "../elements/asset-upload";

export const RewardForm: FC<IFormProps<IReward>> = (props) => {
  return (
    <Form {...props}>
      <input type="hidden" name="id" value={props.model?.id} />
      <input type="hidden" name="_id" value={props.model?.id} />
      <input
        type="hidden"
        name="organisationId"
        value={props.model?.organisationId}
      />

      <FormRow>
        <AssetUpload
          name="assetId"
          asset={props.model?.asset}
          disabled={props.disabled}
        />
      </FormRow>

      <FormRow>
        <Input
          label={dictionary.title}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.title,
            name: "title",
          }}
        />
      </FormRow>
      <FormRow>
        <Textarea
          label={dictionary.description}
          attributes={{
            disabled: props.disabled,
            defaultValue: props.model?.description,
            name: "description",
            required: true,
          }}
        />
      </FormRow>
      <FormRow>
        <Input
          label={dictionary.required_points}
          attributes={{
            type: "number",
            disabled: props.disabled,
            defaultValue: props.model?.requiredPoints,
            name: "requiredPoints",
            required: true,
          }}
        />
      </FormRow>

      <FormRow>
        <FormRow>
          <Button
            attributes={{ type: "submit", disabled: props.disabled }}
            appearance="primary"
          >
            {dictionary.submit}
          </Button>
        </FormRow>
      </FormRow>
    </Form>
  );
};
